import { forwardRef, useImperativeHandle, useState, useEffect, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Walletgeticon, Walletsellicon } from "../../assets/icons/icon";
import "./TradingTrade.css";
import { useApp } from "../../context/AppContext";

const TradingTrade = forwardRef(({ tradeMode, mobile }, ref) => {
  const { exchange, currency } = useParams();
  const location = useLocation();
  const { balance, service, apiKey, apiSecret } = useApp();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const tradeToken = useMemo(
    () => ({
      buyPrice: queryParams.get("buyPrice"),
      sellPrice: queryParams.get("sellPrice"),
      buyVolume: queryParams.get("buyVolume"),
      sellVolume: queryParams.get("sellVolume"),
      amount: queryParams.get("amount"),
      logoURI: queryParams.get("logoURI"),
      contractAddress: queryParams.get("contractAddress"),
      jup: queryParams.get("jup"),
      currencyA: currency.split("_")[0],
      stockA: exchange,
    }),
    [queryParams, currency, exchange]
  );
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [tokenBalance, setTokenBalance] = useState({ available: 0, locked: 0 });
  const isBuyMode = tradeMode === "buy";

  // withdrawState'i kaldırdık çünkü artık gerekli değil

  useEffect(() => {
    let currency = isBuyMode ? "USDT" : tradeToken.currencyA;
    let result = balance.find((item) => item.currency === currency);
    if (result) {
      setTokenBalance({ available: result.available, locked: result.locked });
    } else {
      setTokenBalance({ available: 0, locked: 0 });
    }
  }, [balance, tradeToken.currencyA, isBuyMode]);

  useEffect(() => {
    if (isBuyMode) {
      setPrice(tradeToken.sellPrice || 0);
      setAmount(tradeToken.sellVolume || 0);
      let initialTotal = (tradeToken.sellPrice || 0) * (tradeToken.sellVolume || 0);
      if (initialTotal > tokenBalance.available) {
        initialTotal = tokenBalance.available;
        setAmount(initialTotal / (tradeToken.sellPrice || 1));
      }
      setTotal(initialTotal);
    } else {
      setPrice(tradeToken.buyPrice || 0);
      setAmount(tradeToken.buyVolume || 0);
      let initialTotal = (tradeToken.buyPrice || 0) * (tradeToken.buyVolume || 0);
      if (initialTotal > tokenBalance.available) {
        initialTotal = tokenBalance.available;
        setAmount(initialTotal / (tradeToken.buyPrice || 1));
      }
      setTotal(initialTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeToken, isBuyMode]);

  useImperativeHandle(ref, () => ({
    setPrice,
    setAmount,
    setTotal,
    tokenBalance,
  }));

  const handlePriceChange = (e) => {
    const newPrice = parseFloat(e.target.value) || 0;
    setPrice(newPrice);
    if (amount) {
      let newTotal = newPrice * amount;
      if (newTotal > tokenBalance.available) {
        newTotal = tokenBalance.available;
        setTotal(newTotal);
        setAmount(newTotal / newPrice);
      } else {
        setTotal(newTotal);
      }
    }
  };

  const handleAmountChange = (e) => {
    let newAmount = parseFloat(e.target.value) || 0;
    let newTotal = price * newAmount;
    if (newTotal > tokenBalance.available) {
      newTotal = tokenBalance.available;
      newAmount = newTotal / (price || 1);
      setAmount(newAmount);
      setTotal(newTotal);
    } else {
      setAmount(newAmount);
      setTotal(newTotal);
    }
  };

  const handleTotalChange = (e) => {
    let newTotal = parseFloat(e.target.value) || 0;
    if (newTotal > tokenBalance.available) {
      newTotal = tokenBalance.available;
    }
    setTotal(newTotal);
    if (price) {
      setAmount(newTotal / price);
    }
  };

  const handleClick = (percentage) => {
    let newTotal;
    if (isBuyMode) {
      newTotal = (percentage / 100) * tokenBalance.available;
      if (newTotal > tokenBalance.available) {
        newTotal = tokenBalance.available;
      }
      const newAmount = newTotal / (price || 1);
      setAmount(newAmount);
      setTotal(newTotal);
    } else {
      let newAmount = (percentage / 100) * tokenBalance.available;
      if (price) {
        newTotal = newAmount * price;
        if (newTotal > tokenBalance.available) {
          newTotal = tokenBalance.available;
          newAmount = newTotal / price;
          setAmount(newAmount);
          setTotal(newTotal);
        } else {
          setAmount(newAmount);
          setTotal(newTotal);
        }
      } else {
        setAmount(newAmount);
      }
    }
  };

  const createOrder = async (e) => {
    e.preventDefault();
    if (total < 6) {
      alert("Minimum işlem tutarı 6 USD olmalıdır.");
      return;
    }
    if (total > tokenBalance.available) {
      alert("Bakiyenizden fazla tutarda işlem yapamazsınız.");
      setTotal(tokenBalance.available);
      setAmount(tokenBalance.available / price);
      return;
    }
    if (!isBuyMode && amount > tokenBalance.available) {
      alert("Bakiyenizden fazla miktarda işlem yapamazsınız.");
      setAmount(tokenBalance.available);
      setTotal(tokenBalance.available * price);
      return;
    }
    try {
      const order = await service.Spot.createOrder(
        "limit",
        `${tradeToken.currencyA}_USDT`,
        isBuyMode ? "buy" : "sell",
        amount,
        total,
        price,
        { key: apiKey, secret: apiSecret }
      );
      console.log("Order created:", order);
    } catch (error) {
      console.error("Order creation error:", error);
    }
  };

  // Yeni handleFastWithdraw fonksiyonunu ekliyoruz
  const handleFastWithdraw = async () => {
    const currency = isBuyMode ? "USDT" : tradeToken.currencyA;
    const amount = tokenBalance.available;
    const address = localStorage.getItem(`${exchange}-Private-Wallet`);
    console.log(address)
    console.log(exchange)
    const chain = exchange === "Mexc" ? "Solana(SOL)" : "SOL";

    if (!address) {
      alert("Lütfen öncelikle çekim adresinizi ayarlayın.");
      return;
    }

    if (amount <= 0) {
      alert("Çekilecek bir bakiye bulunmamaktadır.");
      return;
    }

    const params = {
      currency: currency,
      address: address,
      amount: amount,
      memo: "",
      chain: chain,
    };

    const headers = {
      key: apiKey,
      secret: apiSecret,
    };

    try {
      console.log("Çekim parametreleri:", params, headers);
      await service.Wallet.withdraw(params, headers);
      alert("Çekim işlemi başlatıldı.");
    } catch (error) {
      console.error("Çekim işlemi sırasında hata oluştu:", error);
    
      alert(`Hata oluştu: ${JSON.stringify(error)}`);
    }
  };

  return (
    <div className={`trading__trade ${mobile === undefined ? '' : mobile }`}>
      <div className="trading__main">
        <form
          className={`trading__form ${isBuyMode ? "buy" : "sell"}`}
          onSubmit={createOrder}
        >
          <label>
            <span>Fiyat</span>
            <input
              type="number"
              placeholder="0.00"
              value={price}
              onChange={handlePriceChange}
            />
          </label>
          <label>
            <span>Adet</span>
            <input
              type="number"
              placeholder="0.00"
              value={amount}
              onChange={handleAmountChange}
            />
          </label>
          <label>
            <span>Toplam</span>
            <input
              type="number"
              placeholder="0.00"
              value={total}
              onChange={handleTotalChange}
            />
          </label>
          <button
            type="submit"
            className={`trading__form-${isBuyMode ? "buy" : "sell"}`}
          >
            {isBuyMode ? "Alış Emri Ver" : "Satış Emri Ver"}
          </button>
        </form>
        <div className="sidebar">
          {[25, 50, 75, 100].map((percentage) => (
            <button
              key={percentage}
              onClick={() => handleClick(percentage)}
              className="sidebar__button"
            >
              {percentage}%
            </button>
          ))}
        </div>
      </div>
      <div className="trading__balance d-flex-desktop">
        <div>
          <div className="balance__total">
            <span>
              <span className="w-50 d-inline-block">Mevcut</span> {isBuyMode ? "USDT" : tradeToken.currencyA} :{" "}
              {parseFloat(tokenBalance.available).toFixed(2)}
            </span>
          </div>
          <div className="balance__available">
            <span>
              <span className="w-50 d-inline-block">Kilitli</span> {isBuyMode ? "USDT" : tradeToken.currencyA} :{" "}
              {parseFloat(tokenBalance.locked).toFixed(2)}
            </span>
          </div>
        </div>
        <div className="balance__transfer">
          <Link to={`/deposit/${isBuyMode ? "USDT" : tradeToken.currencyA}`}>
            <button className="balance__get al-icon-btn">
              {Walletgeticon}
            </button>
          </Link>
          <Link to={`/withdraw/${isBuyMode ? "USDT" : tradeToken.currencyA}`}>
            <button className="balance__sell sat-icon-btn">
              {Walletsellicon}
            </button>
          </Link>
          <button className="fast-icon-btn" onClick={handleFastWithdraw}>
            {Walletsellicon}
          </button>
        </div>
      </div>
    </div>
  );
});

export default TradingTrade;

import React, { useEffect, useState } from "react";
import "./JupiterTerminal.css";
import { useApp } from "../../context/AppContext";

function JupiterTerminal() {
  const { tradeToken } = useApp();
  const USDT = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB";

  // State variable for displayMode
  const [displayMode, setDisplayMode] = useState(
    window.innerWidth < 800 ? "widget" : "integrated"
  );

  // Event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      const newDisplayMode = window.innerWidth < 800 ? "widget" : "integrated";
      setDisplayMode(newDisplayMode);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Jupiter initialization effect
  useEffect(() => {
    if (window.Jupiter && tradeToken) {
      let initialInputMint, initialOutputMint;

      if (tradeToken.jup === "buy") {
        initialInputMint = USDT;
        initialOutputMint = tradeToken.contractAddress;
      } else if (tradeToken.jup === "sell") {
        initialInputMint = tradeToken.contractAddress;
        initialOutputMint = USDT;
      }

      // Clean up previous instance if necessary
      if (window.jupInstance) {
        window.jupInstance.destroy();
      }

      // Initialize Jupiter with dynamic displayMode
      window.Jupiter.init({
        displayMode: displayMode,
        integratedTargetId: "integrated-terminal",
        endpoint: "https://grateful-jerrie-fast-mainnet.helius-rpc.com/",
        containerStyles: { color: "red" },
        strictTokenList: true,
        widgetStyle: {
          position: "bottom-right",
          size: "sm",
        },
        formProps: {
          initialOutputMint: initialOutputMint,
          initialInputMint: initialInputMint,
          initialAmount: tradeToken.amount,
        },
      })
        .then((instance) => {
          console.log("Jupiter initialized");
          // Store the instance to destroy it later if needed
          window.jupInstance = instance;
        })
        .catch((error) => {
          console.error("Error initializing Jupiter", error);
        });
    }
  }, [tradeToken, displayMode]);

  return <div className="terminal" id="integrated-terminal"></div>;
}

export default JupiterTerminal;

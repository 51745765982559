import axios from "axios";

// API URL tanımı
const API_URL = "https://mexc.gangue.io/";
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// API isteklerini yapacak temel fonksiyon
const sendRequest = async (
  method,
  path,
  data = {},
  params = {},
  headers = {}
) => {
  try {
    const response = await axiosInstance({
      method,
      url: path,
      params: params,
      data: data,
      headers: {
        "Content-Type": "application/json",
        key: headers.key,
        secret: headers.secret,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${path}:`, error.response.data);
    throw error.response.data;
  }
};

export const Wallet = {
  // Para çekme işlemi
  // currency: Para birimi (ör. 'BTC')
  // address: Çekim yapılacak adres
  // amount: Çekim miktarı
  // memo: Memo (opsiyonel)
  // chain: Blockchain ağı
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.withdraw('BTC', '1BitcoinAddress...', 0.5, 'OptionalMemo', 'BTC-Network', { key: 'api-key', secret: 'api-secret' })
  withdraw: async (params, headers) => {
    return await sendRequest("POST", "/wallet/withdraw", params, {}, headers);
  },

  // Mevduat adresi getirme
  // currency: Para birimi (ör. 'BTC')
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.depositAddress('BTC', { key: 'api-key', secret: 'api-secret' })
  depositAddress: async (currency, headers) => {
    const params = { currency };
    return await sendRequest(
      "GET",
      "/wallet/depositAddress",
      {},
      params,
      headers
    );
  },

  // Kayıtlı adresleri getirme
  // currency: Para birimi (ör. 'BTC')
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.savedAddress('BTC', { key: 'api-key', secret: 'api-secret' })
  savedAddress: async (currency, headers) => {
    const cacheKey = `MexcsavedAddressCache_${currency}`;
    const cacheTimestampKey = `MexcsavedAddressTimestamp_${currency}`;
    const oneHour = 60 * 60 * 8000; // 1 saat (milisaniye cinsinden)

    // Local storage'dan zaman damgasını ve veriyi al
    const cachedResponse = JSON.parse(localStorage.getItem(cacheKey));
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

    // Eğer cache 1 saatten daha eskiyse veya cache yoksa yeni istek yap
    if (
      !cachedResponse ||
      !cachedTimestamp ||
      Date.now() - cachedTimestamp > oneHour
    ) {
      const params = { currency };
      const response = await sendRequest(
        "GET",
        "/wallet/savedAddress",
        {},
        params,
        headers
      );

      // Yanıtı ve zaman damgasını local storage'a kaydet
      localStorage.setItem(cacheKey, JSON.stringify(response));
      localStorage.setItem(cacheTimestampKey, Date.now().toString());

      return response;
    }

    // Eğer cache güncelse local storage'daki veriyi döndür
    return cachedResponse;
  },

  // Çekim işlemini iptal etme
  // orderId: Çekim işlemi ID'si
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.cancelWithdraw('orderId123', { key: 'api-key', secret: 'api-secret' })
  cancelWithdraw: async (orderId, headers) => {
    const params = { orderId };
    return await sendRequest(
      "DELETE",
      "/wallet/cancelWithdraw",
      {},
      params,
      headers
    );
  },

  // Çekim geçmişi
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.withdrawHistory({ key: 'api-key', secret: 'api-secret' })
  withdrawHistory: async (headers) => {
    return await sendRequest("GET", "/wallet/withdrawHistory", {}, {}, headers);
  },

  // Mevduat geçmişi
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.depositHistory({ key: 'api-key', secret: 'api-secret' })
  depositHistory: async (headers) => {
    return await sendRequest("GET", "/wallet/depositHistory", {}, {}, headers);
  },

  // Hesap bakiyesi
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.accountBalance({ key: 'api-key', secret: 'api-secret' })
  accountBalance: async (headers) => {
    return await sendRequest("GET", "/wallet/accountBalance", {}, {}, headers);
  },

  onChain: async (currency, headers) => {
    const chains = await sendRequest(
      "GET",
      "/wallet/tokenInfo",
      {},
      { currency },
      headers
    );

    // Filtreleme işlemi burada yapılıyor
    const filteredChains = chains.filter(
      (chain) =>
        chain.is_deposit_disabled === 0 &&
        chain.is_withdraw_disabled === 0 &&
        (chain.chainName === "SOLANA" ||
          chain.chainName === "Tron-TRC20" ||
          chain.chainName === "BSC-BEP20")
    );

    return filteredChains;
  },
  // Dinleme anahtarı oluşturma
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.createListenKey({ key: 'api-key', secret: 'api-secret' })
  createListenKey: async (headers) => {
    return await sendRequest("POST", "/userDataStream", {}, {}, headers);
  },

  // Dinleme anahtarı getirme
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.getListenKey({ key: 'api-key', secret: 'api-secret' })
  getListenKey: async (headers) => {
    const response = await sendRequest(
      "GET",
      "/userDataStream",
      {},
      {},
      headers
    );
    return response;
  },

  // Dinleme anahtarı aktif tutma
  // listenKey: Dinleme anahtarı
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.keepaliveListenKey('listenKey123', { key: 'api-key', secret: 'api-secret' })
  keepaliveListenKey: async (listenKey, headers) => {
    const params = { listenKey };
    return await sendRequest("PUT", "/userDataStream", {}, params, headers);
  },

  // Dinleme anahtarı silme
  // listenKey: Dinleme anahtarı
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.deleteListenKey('listenKey123', { key: 'api-key', secret: 'api-secret' })
  deleteListenKey: async (listenKey, headers) => {
    const params = { listenKey };
    return await sendRequest("DELETE", "/userDataStream", {}, params, headers);
  },
};
